import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';
import { FC } from 'react';
import * as S from './StockFormWrapper.styles';
import Duplicate from '@assets/icons/duplicate';
import { ReactComponent as DuplicateIcon } from '@assets/icons/duplicate.svg';
import StockForm from '@components/modules/mobile/products_stocks_edit/StockForm/StockForm';
import TitleBar from '@components/modules/mobile/products_stocks_edit/TitleBar/TitleBar';
import ConfirmPopUp from '@components/modules/common/ConfirmPopUp/ConfirmPopUp';
import type { Product, Stocks, Todo } from '@lib/common/type';
import useCases from '@lib/mobile/common/hooks/useCases';
import useUpdateStockMutation from '@lib/mobile/products_stocks_edit/useUpdateStockMutation';
import useDeleteStockMutation from '@lib/mobile/products_stocks_edit/useDeleteStockMutation';
import useDuplicateLatestStock from '@lib/mobile/products_stocks_edit/useDuplicateLatestStock';
import UpdatePopUp from '@components/modules/common/UpdatePopUp/UpdatePopUp';
import ErrorMsgPopUp from '@components/modules/common/ErrorMsgPopUp/ErrorMsgPopUp';
import FloatingAddButton from '@components/modules/common/mobile/FloatingAddButton/FloatingAddButton';
import type { InputParams } from '@lib/stock_status/type';
import { totalCheck, calcTotalFunc } from '@lib/stock_status/functions';
import { useDeviceOrientationAndSize } from '@lib/common/functions';
import useDuplicateEstimatedStock from '@lib/mobile/products_stocks_edit/useDuplicateEstimatedStock';

type Props = {
  id: string;
  date: Date;
  stock_kind: string;
  storeroom_id: string;
  product: Product;
  stocks: Stocks[];
  refetch: () => void;
};

const StockFormWrapper: FC<Props> = ({
  id,
  date,
  stock_kind,
  storeroom_id,
  product,
  stocks,
  refetch,
}: Props) => {
  const initializeAmount = {
    amountDetailId: '',
    bara: '',
    case: '',
    total: '',
    caseId: 0,
    piecesPerBox: 1,
    productionOrShippingDate: '',
  };

  // TODO: APIでくるのは数値で入力値は文字列なので変換をどこかでしないといけない
  const [initializeDetail] = useState<Todo>(initializeAmount);
  const [initializeAmountDetail] = useState<Todo>(initializeAmount);
  const stock = stocks?.find((stock) => moment(date).isSame(stock.date, 'day'));
  const [inputParams, setInputParams] = useState<InputParams>({
    amountDetail: stock?.amountDetail || initializeDetail,
    comment: stock?.comment || '',
  });
  const [inputAmountParams, setInputAmountParams] = useState<Todo>([]);
  // SidebarInputの入力フォーム（日付、ケース、バラ）、備考への変更有無
  const [isChanged, setIsChanged] = useState(false);
  // 日付選択のカウント
  const [count, setCount] = useState(0);
  // 削除ボタン押した日付をセット
  const [deleteId, setDeleteId] = useState<number | null>(null);
  // 下位のコンポーネントに渡しているのがネストの深いオブジェクトなので、強制再レンダリングする必要がある
  const [, setToggleValue] = useState(false);
  const reRender = () => setToggleValue((prev) => !prev);
  const [popUp, setPopUp] = useState(false);
  const [messageKind, setMessageKind] = useState('');
  const [isDelete, setIsDelete] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [confirmMsg, setConfirmMsg] = useState('');
  const [confirmKind, setConfirmKind] = useState('');
  const [selectedCasePiecesPerCase, setSelectedCasePiecesPerCase] = useState(
    () => {
      // 初期化時にローカルストレージから値を読み込む
      const savedValue = localStorage.getItem('selectedCasePiecesPerCase');
      return savedValue ? Number(savedValue) : 1;
    }
  );
  const history = useHistory();
  const { state } = useLocation();

  const formattedDate = moment(date).format('YYYY/MM/DD (ddd)');
  const { data: cases } = useCases();
  // デバイス、それら状態の判定
  const { isPortrait, deviceType } = useDeviceOrientationAndSize();
  const isPortraitAndTablet = isPortrait && deviceType === 'tablet';

  // 完成品または半製品のの該当商品ケース
  const filteredProductCases = cases?.filter(
    (c) => c.productId === product.id && c.productKind === product.productKind
  );

  const handleClickFloatingAddButton = () => {
    const infoWithCase = {
      ...initializeAmountDetail,
      caseId: filteredProductCases && filteredProductCases[0]?.caseId,
      piecesPerBox:
        filteredProductCases && filteredProductCases[0]?.piecesPerBox,
    };
    setInputAmountParams([...inputAmountParams, infoWithCase]);
    setPopUp(false);
    const element = document.documentElement;
    const bottom = element.scrollHeight - element.clientHeight;
    window.scroll(0, bottom);
  };

  // 基準日1日戻る
  const getBeforeDate = (date: Date) => {
    if (inputAmountParams.length > 0 || isChanged) {
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
      setConfirmKind('BeforeDate');
      setPopUp(false);
    } else {
      getBeforeDateAfterConfirm(date);
    }
  };

  const getBeforeDateAfterConfirm = (date: Date) => {
    date.setDate(date.getDate() - 1);
    setCount(count - 1);
    setInputParams({
      amountDetail:
        stocks
          ?.find((stock) => moment(date).isSame(stock.date, 'day'))
          ?.amountDetail.map((item) => ({
            ...item,
            bara: item.bara === 0 ? '0' : item.bara,
            total: item.total === 0 ? '0' : item.total,
          })) || initializeDetail,
      comment:
        stocks?.find((stock) => moment(date).isSame(stock.date, 'day'))
          ?.comment || '',
    });
    setInputAmountParams([]);
  };

  // 基準日1日進む
  const getNextDate = (date: Date) => {
    if (inputAmountParams.length > 0 || isChanged) {
      setConfirmMsg('保存せずに編集を終了します。よろしいですか。');
      setConfirmKind('NextDate');
      setPopUp(false);
    } else {
      getNextDateAfterConfirm(date);
    }
  };

  const getNextDateAfterConfirm = (date: Date) => {
    // 現在日付けが過去日のときのみ、日付を進められる。
    if (count < 0) {
      date.setDate(date.getDate() + 1);
      setCount(count + 1);
    }
    setInputParams({
      amountDetail:
        stocks
          ?.find((stock) => moment(date).isSame(stock.date, 'day'))
          ?.amountDetail.map((item) => ({
            ...item,
            bara: item.bara === 0 ? '0' : item.bara,
            total: item.total === 0 ? '0' : item.total,
          })) || initializeDetail,
      comment:
        stocks?.find((stock) => moment(date).isSame(stock.date, 'day'))
          ?.comment || '',
    });
    setInputAmountParams([]);
  };

  // 台車とケースとバラの計算
  const calcTotal = (
    index: number,
    value: string,
    valueKind: 'bara' | 'case'
  ) => {
    setInputParams((prevParams) => {
      const detailKey = `amountDetail`;
      const oldDetail = prevParams[detailKey][index];
      const newDetail = { ...oldDetail, [valueKind]: value };
      calcTotalFunc(
        product,
        newDetail,
        Number(localStorage.getItem('selectedCasePiecesPerCase'))
      );
      prevParams[detailKey][index] = newDetail;
      return prevParams;
    });
    reRender();
  };

  // 日付の選択と更新
  const changeDate = (index: number, value: Date | string) => {
    setInputParams((prevParams) => {
      const detailKey = `amountDetail`;
      const oldDate = prevParams[detailKey][index];
      const newDate = { ...oldDate, productionOrShippingDate: value };
      prevParams[detailKey][index] = newDate;
      return prevParams;
    });
    reRender();
  };

  // ケースの選択と更新
  const changeCase = (index: number, value: number) => {
    setInputParams((prevParams) => {
      const detailKey = `amountDetail`;
      const oldDate = prevParams[detailKey][index];
      const newDate = { ...oldDate, caseId: value };
      prevParams[detailKey][index] = newDate;
      return prevParams;
    });
    reRender();
  };

  // inputコンポーネントの台車とケースとバラの計算
  const newCalcTotal = (
    index: number,
    value: string,
    valueKind: 'bara' | 'case'
  ) => {
    setInputAmountParams((newParams: Todo) => {
      const oldDetail = newParams[index];
      const newDetail = { ...oldDetail, [valueKind]: value };
      calcTotalFunc(product, newDetail, newDetail.piecesPerBox);
      newParams[index] = newDetail;
      return newParams;
    });
    reRender();
  };

  // inputコンポーネントの日付の選択と更新
  const changeNewDate = (index: number, value: Todo) => {
    setInputAmountParams((newParams: Todo) => {
      const oldDate = newParams[index];
      const newDate = { ...oldDate, productionOrShippingDate: value };
      newParams[index] = newDate;
      return newParams;
    });
    reRender();
  };

  // inputコンポーネントのケースの選択と更新
  const changeNewCase = (index: number, value: number) => {
    setInputAmountParams((newParams: Todo) => {
      const oldDate = newParams[index];
      const newDate = {
        ...oldDate,
        caseId: value,
        piecesPerBox: selectedCasePiecesPerCase,
      };
      newParams[index] = newDate;
      return newParams;
    });
    reRender();
  };

  const setLatestData = async () => {
    await refetch();
    const stock = await stocks?.find((stock) =>
      moment(date).isSame(stock.date, 'day')
    );
    setInputParams({
      amountDetail:
        stock?.amountDetail.map((item) => ({
          ...item,
          bara: item.bara === 0 ? '0' : item.bara,
          total: item.total === 0 ? '0' : item.total,
        })) || initializeAmountDetail,
      comment: stock?.comment || '',
    });
    reRender();
  };

  const handleDeleteSuccess = () => {
    setLatestData();
    setDeleteId(null);
    setIsDelete(true);
    setMessageKind('delete');
    setPopUp(!popUp);
  };
  const deleteStockMutation = useDeleteStockMutation(
    stock?.actualProductStocksId || stock?.actualSemiProductStocksId,
    stock_kind,
    deleteId,
    handleDeleteSuccess
  );
  // 削除ボタンでレコードを削除する
  const handleDelete = () => {
    deleteStockMutation.mutate();
  };

  const handleSuccess = () => {
    setLatestData();
    setIsDelete(false);
    setIsChanged(false);
    setMessageKind('create');
    setPopUp(!popUp);
    setInputAmountParams([]);
    setSelectedCasePiecesPerCase(1);
  };

  const updateStockMutation = useUpdateStockMutation(
    id,
    stock_kind,
    date,
    storeroom_id,
    handleSuccess,
    setErrMsg
  );

  // 予定在庫の取得
  const { duplicateEstimatedStock: estimatedStock } =
    useDuplicateEstimatedStock(id, storeroom_id, date, stock_kind);

  const { duplicateLatestStock: latestStock } = useDuplicateLatestStock(
    id,
    storeroom_id,
    date,
    stock_kind
  );

  const onClickSubmit = () => {
    // ケースの種類が１種類の場合、日付のみ重複チェック
    if (totalCheck(inputParams, inputAmountParams)) {
      setErrMsg('在庫数は必ず入力してください。');
    } else if (!(inputAmountParams.length > 0 || isChanged)) {
      setErrMsg('変更するデータはありません。');
    } else {
      if (inputParams.amountDetail) {
        updateStockMutation.mutate({
          amountDetail: Object.assign(
            inputParams.amountDetail.concat([...inputAmountParams])
          ),
          comment: inputParams.comment,
        });
      } else {
        updateStockMutation.mutate({
          amountDetail: [...inputAmountParams],
          comment: inputParams.comment,
        });
      }
    }
  };

  // 確認メッセージOK
  const handleOk = () => {
    setIsChanged(false);
    setConfirmMsg('');
    if (confirmKind === 'ProductList') {
      history.push(`/mobile/products/for_stocks`, state);
    } else if (confirmKind === 'NextDate') {
      getNextDateAfterConfirm(date);
    } else if (confirmKind === 'BeforeDate') {
      getBeforeDateAfterConfirm(date);
    }
  };
  // 確認メッセージキャンセル
  const handleCancel = () => {
    setConfirmMsg('');
  };

  // データ作成、更新後、作成したデータ表示に必要
  useEffect(() => {
    const stock = stocks?.find((stock) =>
      moment(date).isSame(stock.date, 'day')
    );
    setInputParams({
      amountDetail: stock?.amountDetail
        ? stock.amountDetail.map((item) => ({
            ...item,
            bara: item.bara === 0 ? '0' : item.bara,
            total: item.total === 0 ? '0' : item.total,
          }))
        : initializeDetail,
      comment: stock?.comment ? stock.comment : '',
    });
  }, [stocks]);

  // selectedCasePiecesPerCaseが変更されたときにローカルストレージに保存
  // numpadを使用すると必ずselectedCasePiecesPerCaseが初期値になってしまうため
  useEffect(() => {
    localStorage.setItem(
      'selectedCasePiecesPerCase',
      selectedCasePiecesPerCase.toString()
    );
  }, [selectedCasePiecesPerCase]);

  return (
    <>
      <TitleBar
        productId={product.id}
        productKind={product.productKind}
        productName={product.name}
        inputAmountParams={inputAmountParams}
        isChanged={isChanged}
        setConfirmMsg={setConfirmMsg}
        setConfirmKind={setConfirmKind}
        setPopUp={setPopUp}
      />

      <StockForm
        filteredProductCases={filteredProductCases}
        product={product}
        inputParams={inputParams}
        selectedDate={date}
        count={count}
        inputAmountParams={inputAmountParams}
        setInputAmountParams={setInputAmountParams}
        setDeleteId={setDeleteId}
        getBeforeDate={getBeforeDate}
        getNextDate={getNextDate}
        setInputParams={setInputParams}
        calcTotal={calcTotal}
        changeDate={changeDate}
        changeCase={changeCase}
        newCalcTotal={newCalcTotal}
        changeNewDate={changeNewDate}
        changeNewCase={changeNewCase}
        handleDelete={handleDelete}
        deleteId={deleteId}
        setIsChanged={setIsChanged}
        selectedCasePiecesPerCase={selectedCasePiecesPerCase}
        setSelectedCasePiecesPerCase={setSelectedCasePiecesPerCase}
      />
      <S.Footer isPortraitAndTablet={isPortraitAndTablet}>
        {(inputParams === undefined && inputAmountParams?.length === 0) ||
        (inputParams?.amountDetail &&
          inputParams?.amountDetail[0]?.total === '' &&
          inputParams?.amountDetail[0]?.productionOrShippingDate === '' &&
          inputAmountParams?.length === 0) ? (
          <S.DuplicateButtonArea>
            <S.EstimatedSubmitButton
              onClick={() => {
                setInputParams(initializeDetail);
                setInputAmountParams(
                  estimatedStock.map((item) => ({
                    ...item,
                    bara: item.bara === 0 ? '0' : item.bara,
                    total: item.total === 0 ? '0' : item.total,
                  }))
                );
              }}
              disabled={!estimatedStock || estimatedStock?.[0]?.total === ''}
            >
              予定在庫情報
              {!estimatedStock || estimatedStock?.[0]?.total === '' ? (
                <Duplicate color={'#6B675C'} />
              ) : (
                <DuplicateIcon />
              )}
            </S.EstimatedSubmitButton>
            <S.SubmitButton
              isCopy={true}
              onClick={() => {
                setInputParams(initializeDetail);
                setInputAmountParams(
                  latestStock.map((item) => ({
                    ...item,
                    bara: item.bara === 0 ? '0' : item.bara,
                    total: item.total === 0 ? '0' : item.total,
                  }))
                );
              }}
              disabled={!latestStock}
            >
              前回在庫情報
              <Duplicate color={'#FFFFFF'} />
            </S.SubmitButton>
          </S.DuplicateButtonArea>
        ) : (
          <S.SubmitButton
            onClick={() => {
              onClickSubmit();
            }}
          >
            在庫を登録する
          </S.SubmitButton>
        )}
      </S.Footer>
      <UpdatePopUp
        popUp={popUp}
        formattedDate={formattedDate}
        productName={product.name}
        handleClose={() => {
          setPopUp(!popUp);
        }}
        fromPc={false}
        isDelete={isDelete}
        taskKind={'stock'}
        messageKind={messageKind}
        isPortraitAndTablet={isPortraitAndTablet}
      />
      <ConfirmPopUp
        fromPc={false}
        confirmMsg={confirmMsg}
        handleOk={handleOk}
        handleCancel={handleCancel}
        isPortraitAndTablet={isPortraitAndTablet}
      />
      <FloatingAddButton handleClick={handleClickFloatingAddButton} />
      <ErrorMsgPopUp
        errMsg={errMsg}
        handleClose={() => setErrMsg('')}
        isPortraitAndTablet={isPortraitAndTablet}
      />
    </>
  );
};

export default StockFormWrapper;
